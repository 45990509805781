import { jwtDecode } from "jwt-decode";

import AxiosInstance from "../utils/axios";

class JwtService {
  init() {
    this.setInterceptors();
  }

  setInterceptors = () => {
    AxiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      async (err) => {
        const originalConfig = err.config;
        if (err.response) {
          if (err.response.status === 401 && originalConfig) {
            // if you ever get an unauthorized response, logout the user
            this.setSession(null);
          }
        }
        return Promise.reject(err);
      }
    );
  };

  handleAuthentication = async () => {
    const access_token = this.getAccessToken();
    if (!access_token) {
      return;
    }

    if (this.isAuthTokenValid(access_token)) {
      this.setSession(access_token);
    } else {
      this.setSession("");
    }
  };

  signInWithAddressAndSignature = async (
    address: string,
    signature: string,
    plainText: string,
  ): Promise<string | null> => {
    // alert(`AUTH here`)
    try {
      const response = await AxiosInstance.post("/auth/login", {
        address,
        signature,
        plainText
      });
      // alert(`AUTH RES: ${JSON.stringify(response)}`)
      if (
        response &&
        response.status === 200 &&
        response.data &&
        response.data.token
      ) {
        this.setSession(response.data.token);
        return response.data.token;
      }
      return null;
    } catch (err) {
      // alert(`Error: ${JSON.stringify(err)}`)
      return null;
    }
  };

  setSession = (token: string) => {
    if (token && this.isAuthTokenValid(token)) {
      localStorage.setItem("ccc_dao_access_token", `${token}`);
      AxiosInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    } else {
      localStorage.removeItem("ccc_dao_access_token");
      delete AxiosInstance.defaults.headers.common.Authorization;
    }
  };

  logout = async () => {
    const response = await AxiosInstance.get("/auth/logout");
    this.setSession(null);
  };

  isAuthTokenValid = (accessToken: string) => {
    if (!accessToken) {
      return false;
    }
    try {
      const decoded = jwtDecode(accessToken);
      const currentTime = Date.now() / 1000;
      if (decoded?.exp < currentTime) {
        console.warn("access token expired");
        return false;
      }
    } catch (err) {
      console.warn("invalid access token");
      return false;
    }

    return true;
  };

  getAccessToken = () => {
    return window.localStorage.getItem("ccc_dao_access_token");
  };
}

const instance = new JwtService();
instance.init();

export default instance;
