import { useCallback, useEffect, useState, useContext } from "react";

import { WalletActionButton } from "@tronweb3/tronwallet-adapter-react-ui";

import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import { AuthContext } from "../../contexts/AuthProvider";

import AxiosInstance from "../../utils/axios";

import jwtService from "../../services/jwtService";

import Icon1 from "../../assets/images/icon-1.png";
import Icon2 from "../../assets/images/icon-2.png";
import Icon3 from "../../assets/images/icon-3.png";
import Icon4 from "../../assets/images/icon-4.png";

import './index.css';

const tokenContractAddress = process.env.REACT_APP_TOKEN_CONTRACT_ADDRESS;
// const baseUrl = "https://c0ff-13-208-240-65.ngrok-free.app";
const baseUrl = process.env.REACT_APP_API_URL;
// console.log(baseUrl);

function Profile() {
    const { address, connected, wallet, signMessage } = useWallet();
    const { isAuthenticated } = useContext(AuthContext)
    const [tokenBalance, setTokenBalance] = useState(0);
    const [tokenPercentage, setTokenPercentage] = useState(0);
    const [inviteLink, setInviteLink] = useState<any>();
    const saveUserInfo = async (data: any) => {
        try {
            const res = await AxiosInstance.post(`/save_data_and_get_invitecode`, data);
            const { is_newcode, inviteCode } = res.data;
            if (is_newcode === 0) {
                setInviteLink(`${process.env.REACT_APP_TG_BOT_LINK}?start=${inviteCode}`);
            } else if (is_newcode === 1) {
                setInviteLink(`You have joined to group before.`);
            } else {
                setInviteLink(`Your balance is less than ${process.env.REACT_APP_TG_MIN_VALUE || 1000000}`);
            }
        } catch (error) {
            console.log("error ", error);
        }
    };
    const createUniqueInviteLink = async () => {
        const expireTimestamp = Math.floor(Date.now() / 1000) + 60 * 5; // 5 minutes
        const inviteCode = Math.random().toString(36).substr(2, 8);
        await saveUserInfo({ inviteCode, expire_date: expireTimestamp })
    };

    const getTokenBalance = useCallback(async () => {
        if (isAuthenticated) {
            try {
                try {
                    const response = await AxiosInstance.post(`/getTokenBalance`, {
                        address,
                        tokenContractAddress,
                    });

                    if (response.status === 200) {
                        var tokenBalance = response?.data?.balance || 0;
                        var tokenPercentage = parseFloat((tokenBalance / 1e8).toFixed(2));
                        console.log(tokenBalance, "tokenBalance");
                        setTokenBalance(tokenBalance);
                        setTokenPercentage(tokenPercentage);
                    } else {
                        setTokenBalance(0);
                        setTokenPercentage(0);
                    }
                } catch (error) {
                    setTokenBalance(0);
                    setTokenPercentage(0);
                }
            } catch (error) {
                setTokenBalance(0);
                setTokenPercentage(0);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [address, isAuthenticated]);
    useEffect(() => {
        if (connected && isAuthenticated && tokenBalance >= Number(process.env.REACT_APP_TG_MIN_VALUE)) {
            createUniqueInviteLink();
        } else {
            setInviteLink(false);
        }
    }, [address, tokenBalance, connected, isAuthenticated]);
    useEffect(() => {
        setInviteLink(false);
        const token = jwtService.getAccessToken() ?? ""
        if (connected && address) {
            getTokenBalance();
        }
    }, [connected, address, getTokenBalance]);
    return (
        <div
            className="content"
            style={{ backgroundImage: "url(../../assets/images/bg.png)" }}
        >
            <div className="content-body">
                <div className="wallet-connection-button">
                    <h2>Wallet Connect </h2>
                    <WalletActionButton />
                </div>
                <div className="wallet-info-panel">
                    <h2>Wallet Connection Info</h2>
                    <p>
                        <span>Connection Status:</span>{" "}
                        {connected ? "Connected" : "Disconnected"}
                    </p>
                    <p>
                        <span>Your selected Wallet:</span> {wallet?.adapter.name}
                    </p>
                    <p>
                        <span>Your Address:</span> {address}
                    </p>
                    <p>
                        <span>$CCC Balance:</span> {tokenBalance}
                    </p>
                    <p>
                        <span>$CCC Percentage:</span> {tokenPercentage}
                    </p>

                    {inviteLink ? (
                        <p>
                            <span>Invite Link:</span> <a href={inviteLink} target="_blank" rel="noreferrer">
                                {inviteLink}
                            </a>
                        </p>
                    ) : null}
                </div>

            </div>
            <div className="contact">
                <h2>FIND US ON!</h2>
                <p>team@coconutchicken.com</p>
                <ul className="social__list">
                    <li className="social__item">
                        <a
                            className="d-flex text-decoration-none"
                            href="https://www.youtube.com/@coconutchickentron"
                            target="_blank"
                        >
                            <img src={Icon1} style={{ height: "40px" }} />
                        </a>
                    </li>
                    <li className="social__item">
                        <a
                            className="d-flex text-decoration-none"
                            href="https://twitter.com/CCCTRX20"
                            target="_blank"
                        >
                            <img src={Icon2} style={{ height: "40px" }} />
                        </a>
                    </li>
                    <li className="social__item">
                        <a
                            className="d-flex text-decoration-none"
                            href="https://t.me/coconutchicken_trx"
                            target="_blank"
                        >
                            <img src={Icon3} style={{ height: "40px" }} />
                        </a>
                    </li>
                    <li className="social__item">
                        <a
                            className="d-flex text-decoration-none"
                            href="https://www.tiktok.com/@coconutchickentron"
                            target="_blank"
                        >
                            <img src={Icon4} style={{ height: "40px" }} />
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    );
}

export default Profile;
