import { useMemo } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import type { WalletError } from "@tronweb3/tronwallet-abstract-adapter";
import {
  WalletDisconnectedError,
  WalletNotFoundError,
} from "@tronweb3/tronwallet-abstract-adapter";
import { WalletProvider } from "@tronweb3/tronwallet-adapter-react-hooks";
import { WalletModalProvider } from "@tronweb3/tronwallet-adapter-react-ui";
import toast from "react-hot-toast";
import { TronLinkAdapter } from "@tronweb3/tronwallet-adapters";

import Home from "./pages/home";
import Profile from "./pages/cccDao";
import AuthProvider from "./contexts/AuthProvider";


export function App() {
  function onError(e: WalletError) {
    try {
      if (e instanceof WalletNotFoundError) {
        return toast.error(e.message);
      } else if (e instanceof WalletDisconnectedError) {
        return toast.error(e.message);
      } else return toast.error(e.message);
    } catch (error) {
      console.log(error);
    }
  }
  const adapters = useMemo(function () {
    const tronLinkAdapter = new TronLinkAdapter();

    return [tronLinkAdapter];
  }, []);
  return (
    <WalletProvider
      onError={onError}
      autoConnect={true}
      disableAutoConnectOnLoad={true}
      adapters={adapters}
    >
      <WalletModalProvider>
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/connect-to-dao" element={<Profile />} />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </WalletModalProvider>
    </WalletProvider>
  );
}
