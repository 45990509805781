import { createContext, useState, useEffect } from "react";
import { useWallet } from "@tronweb3/tronwallet-adapter-react-hooks";
import jwtService from "../services/jwtService";

interface AuthContextState {
    isAuthenticated: boolean;
}

export const AuthContext = createContext<AuthContextState>({} as AuthContextState);

const AuthProvider = ({ children }) => {
    const { address, connected, signMessage } = useWallet();
    const [isAuthenticated, setIsAuthenticated] = useState(false);

    useEffect(() => {
        if (connected && address) {
            const accessToken = jwtService.getAccessToken();
            if (!accessToken || !jwtService.isAuthTokenValid(accessToken)) {
                logIn();
            } else {
                setIsAuthenticated(true);
            }
        } else {
            logOut()
            setIsAuthenticated(false);
        }
    }, [connected, address]);

    const logIn = async () => {
        try {
            const projectName = "CCC_DAO";
            const timeStamp = new Date().getTime();
            const plainText = JSON.stringify({
                projectName,
                timeStamp,
                address
            })
            // alert(JSON.stringify(plainText))
            const signature = await signMessage(plainText);
            // alert(signature)
            console.log("login: ", signature);
            const res = await jwtService.signInWithAddressAndSignature(address, signature,plainText);
            setIsAuthenticated(true);
        } catch (error) {
            console.error(`Error in Signing message ${error}`)
            alert(`Error in Signing message ${error}`)
        }

    };

    const logOut = () => {
        jwtService.logout();
        setIsAuthenticated(false);
    };

    return (
        <AuthContext.Provider value={{
            isAuthenticated,
        }}>
            {children}
        </AuthContext.Provider>
    );
};

export default AuthProvider;
