import { FC } from "react";
import { Link } from "react-router-dom";

import "./index.css";
import cDAOLogo from "../../assets/images/Chicken-DAO-logo.png";
import cccLogo from "../../assets/images/Coconut Chicken_Logo.png";
import chicken from "../../assets/images/chicken.png";
import Icon1 from "../../assets/images/icon-1.png";
import Icon2 from "../../assets/images/icon-2.png";
import Icon3 from "../../assets/images/icon-3.png";
import Icon4 from "../../assets/images/icon-4.png";

const Home: FC = () => {
  return (
    <div>
      <div
        className="home"
        style={{ backgroundImage: "url(../../assets/images/bg.png)" }}
      >
        <div className="content">
          <Link to="https://coconutchicken.com">
            <div className="to-backward">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                viewBox="0 0 24 24"
              >
                <g fill="none" fillRule="evenodd">
                  <path d="M24 0v24H0V0zM12.593 23.258l-.011.002l-.071.035l-.02.004l-.014-.004l-.071-.035c-.01-.004-.019-.001-.024.005l-.004.01l-.017.428l.005.02l.01.013l.104.074l.015.004l.012-.004l.104-.074l.012-.016l.004-.017l-.017-.427c-.002-.01-.009-.017-.017-.018m.265-.113l-.013.002l-.185.093l-.01.01l-.003.011l.018.43l.005.012l.008.007l.201.093c.012.004.023 0 .029-.008l.004-.014l-.034-.614c-.003-.012-.01-.02-.02-.022m-.715.002a.023.023 0 0 0-.027.006l-.006.014l-.034.614c0 .012.007.02.017.024l.015-.002l.201-.093l.01-.008l.004-.011l.017-.43l-.003-.012l-.01-.01z" />
                  <path
                    fill="#ec1f24"
                    d="M7.94 13.06a1.5 1.5 0 0 1 0-2.12l5.656-5.658a1.5 1.5 0 1 1 2.121 2.122L11.122 12l4.596 4.596a1.5 1.5 0 1 1-2.12 2.122l-5.66-5.658Z"
                  />
                </g>
              </svg>
              <span>Back to Coconut Chicken</span>
            </div>
          </Link>
          <div className="header">
            <img src={cccLogo} alt="coconutchicken logo" style={{ height: '70px' }} />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="80"
              height="80"
              viewBox="0 0 20 20"
            >
              <path
                fill="black"
                d="M10 0c.423 0 .766.343.766.766v8.467h8.468a.766.766 0 1 1 0 1.533h-8.468v8.468a.766.766 0 1 1-1.532 0l-.001-8.468H.766a.766.766 0 0 1 0-1.532l8.467-.001V.766A.768.768 0 0 1 10 0"
              />
            </svg>
            <img src={cDAOLogo} alt="Chicken-DAO logo" />
          </div>
          <div className="header-title">
            <h2>
              Join Us and Decide the Future of <span>CoconutChicken!</span>
            </h2>
          </div>
          <div className="reason">
            <h3>Why Do We Need ChichenDAO?</h3>
            <p>
              {" "}
              First and foremost, $CCC is a community token, which means it should
              be driven by the community for the community. There are many
              decisions regar-ding the future of the token that the community
              needs to make. The Coconutchicken com roadmap outlines several
              products aimed at generating revenue for $CCC, which will be
              allocated to the marketing wallet. Let's all decide together what to
              do with the upcoming products, revenue, etc. Join us!
            </p>
          </div>
          <div className="guide">
            <h3>How Does ChickenDAO Work?</h3>
            <div className="guide-list">
              <div className="guide-list-item">
                <div className="guide-list-item-no">1</div>
                <p>
                  {" "}
                  To connect to the DAO, your wallet must currently hold a minimum
                  of 10 million $CCC tokens (this amount is subject to change by
                  the DAO).{" "}
                </p>
              </div>
              <div className="guide-list-item">
                <div className="guide-list-item-no">2</div>
                <p> Connect your wallet.. </p>
              </div>
              <div className="guide-list-item">
                <div className="guide-list-item-no">3</div>
                <p>
                  {" "}
                  Use the generated link to gain access to the dedicated $CCC DAO
                  group where all decisions will be made. .{" "}
                </p>
              </div>
              <div className="guide-list-item">
                <div className="guide-list-item-no">4</div>
                <p>
                  {" "}
                  If your wallet holds fewer than 10 million tokens, your access
                  to the group will be automatically revoked .{" "}
                </p>
              </div>
            </div>
          </div>
          <div className="footer">
            <h3>Shape the Future of CoconutChicken Now.</h3>
            <h2>Be One of Us!</h2>
            <button
              onClick={() => {
                window.location.href = "/connect-to-dao";
              }}
            >
              Connect To Dao
            </button>
            <img src={chicken} alt="chicken logo" className="chicken" />
          </div>
        </div>
      </div>
      <div className="contact">
        <h2>FIND US ON!</h2>
        <p>team@coconutchicken.com</p>
        <ul className="social__list">
          <li className="social__item">
            <a
              className="d-flex text-decoration-none"
              href="https://www.youtube.com/@coconutchickentron"
              target="_blank"
            >
              <img src={Icon1} style={{ height: "40px" }} />
            </a>
          </li>
          <li className="social__item">
            <a
              className="d-flex text-decoration-none"
              href="https://twitter.com/CCCTRX20"
              target="_blank"
            >
              <img src={Icon2} style={{ height: "40px" }} />
            </a>
          </li>
          <li className="social__item">
            <a
              className="d-flex text-decoration-none"
              href="https://t.me/coconutchicken_trx"
              target="_blank"
            >
              <img src={Icon3} style={{ height: "40px" }} />
            </a>
          </li>
          <li className="social__item">
            <a
              className="d-flex text-decoration-none"
              href="https://www.tiktok.com/@coconutchickentron"
              target="_blank"
            >
              <img src={Icon4} style={{ height: "40px" }} />
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Home;
